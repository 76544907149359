import classNames from 'classnames';
import Fuse from 'fuse.js';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Image from '../components/image';
import Layout from '../components/layout';
import Link from '../components/link';
import SEO from '../components/SEO';
import sectionConductor from '../sections/section-conductor';
import { resolve } from '../urls';

// Manifesto tiles are layed out in an S-shape
// pattern is every 10/18 tiles, starting from 10 (first is right-aligned)
const MANIFESTO_INDICES = [10, 20, 38, 48, 66, 76, 94];
const SEARCHOPTIONS = {
  keys: ['slug', 'position', 'office.city'],
  shouldSort: true,
  threshold: 0.2,
  minMatchCharLength: 2,
};

class PeoplePage extends Component {
  constructor(props) {
    super(props);

    this.fuse = new Fuse({}, SEARCHOPTIONS);
    this.peopleList = null;
  }

  allPersonsHidden(value) {
    document.querySelectorAll('li[data-person]').forEach((item) => {
      value
        ? item.classList.add('people-tiles__tile--is-hidden')
        : item.classList.remove('people-tiles__tile--is-hidden');
    });
  }

  showPersons(persons) {
    persons.map((person) =>
      document
        .querySelector(`li[data-person=${person.slug}]`)
        .classList.remove('people-tiles__tile--is-hidden')
    );
  }

  onSearch(e) {
    const searchResult = this.fuse.search(e.target.value);
    const peopleTilesList = document.querySelector('.people-tiles__list');

    if (searchResult.length > 0) {
      this.allPersonsHidden(true);
      this.showPersons(searchResult);
      peopleTilesList.classList.add('people-tiles__list--active');
    } else {
      this.allPersonsHidden(false);
      peopleTilesList.classList.remove('people-tiles__list--active');
    }
  }

  render() {
    const { data, location } = this.props; // add more props here as needed
    const {
      contentfulLayout: page,
      allContentfulPersons: people,
      allContentfulSectionManifesto,
    } = data;

    const renderedSections = page.sections.map((section, i) => sectionConductor(section, i));

    const manifesto = allContentfulSectionManifesto.edges[0].node.manifestoList;

    this.fuse.list = people.edges.map((personNode) => personNode.node);

    this.peopleList = people.edges.map(({ node }, i) => (
      <React.Fragment key={`${i}-manifesto`}>
        {MANIFESTO_INDICES.indexOf(i) > -1 && (
          <li className="people-tiles__tile people-tiles__tile--large">
            <div className="manifesto-tile">
              <div className="manifesto-tile__content">
                <p className="manifesto-tile__text">
                  {manifesto[MANIFESTO_INDICES.indexOf(i)].title}
                </p>
                <p
                  className="manifesto-tile__description"
                  dangerouslySetInnerHTML={{
                    __html:
                      manifesto[MANIFESTO_INDICES.indexOf(i)].description.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </li>
        )}
        <li className="people-tiles__tile" data-person={node.slug}>
          {node.slug && (
            <Link to={resolve('people:detail', { peopleSlug: node.slug })} className="person-tile">
              {node.avatar && (
                <div className="person-tile__image-wrapper">
                  <Image
                    className="person-tile__image"
                    image={node.avatar}
                    alt={node.avatar.title}
                  />
                </div>
              )}
              {node.name && (
                <div className="person-tile__name" itemProp="name">
                  {node.name}
                </div>
              )}
            </Link>
          )}
        </li>
      </React.Fragment>
    ));

    const pageWrapClasses = classNames({
      page: true,
      [`page--${page.contentfulid}`]: true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO title={page.title} metadata={page.metadata} />
          <>{renderedSections}</>
          <div className="people-tiles">
            <input
              type="text"
              onChange={(e) => this.onSearch(e)}
              className="edna-input-text__input"
              placeholder="Who are you looking for?"
            />
            <ul className="people-tiles__list">{this.peopleList}</ul>
          </div>
        </Layout>
      </div>
    );
  }
}

export default PeoplePage;

export const pageQuery = graphql`
  query {
    contentfulLayout(contentfulid: { eq: "people" }) {
      contentfulid
      title
      url
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
    allContentfulPersons(
      filter: { active_employee: { eq: true } }
      sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          slug
          position
          office {
            city
          }
          avatar {
            title
            file {
              contentType
            }
            gatsbyImageData(layout: CONSTRAINED, width: 300, aspectRatio: 1)
          }
          active_employee
        }
      }
    }
    allContentfulSectionManifesto {
      edges {
        node {
          manifestoList {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;

PeoplePage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
